<div class="row">
    <div class="col-12">
        <ngx-skeleton-loader
            [theme]="{ width: '75%', height: '40px', 'background-color': backgroundColor() }"
        ></ngx-skeleton-loader>
    </div>
    <div class="col-12">
        <ngx-skeleton-loader [theme]="{ width: '50%', 'background-color': backgroundColor() }"></ngx-skeleton-loader>
    </div>
</div>
