<div class="upper__container mb-5 mb-sm-2">
    <p
        *ngIf="dateFirst"
        [innerHTML]="
            'dynamische_frontend_texte.datum_auswahl.html_content.auswahl'
                | translate
                | replaceDateTime: selectedDate
                | async
        "
        class="mb-0"
    ></p>
    <p
        [innerHTML]="'dynamische_frontend_texte.datum_auswahl.html_content.label_zeit_auswahl' | translate | safeStyle"
    ></p>
</div>
<div *ngIf="!timePickSecondLoading">
    <div class="row" *ngFor="let court of availableAgencies">
        <div class="col-sm-4 col-6 pr-sm-1" *ngFor="let singleTime of court.times; let first = first">
            <div class="time--wrapper">
                <a
                    *ngIf="first"
                    #first
                    tabindex="0"
                    [attr.title]="
                        'dynamische_frontend_texte.datum_auswahl.attribute_text.zeit_auswahl_eintrag_title'
                            | translate
                            | replaceDateTime: singleTime.time
                            | async
                    "
                    (click)="timeClick(singleTime, $event)"
                    href="#"
                    [innerHTML]="
                        'dynamische_frontend_texte.datum_auswahl.html_content.zeit_auswahl_eintrag'
                            | translate
                            | replaceDateTime: singleTime.time
                            | async
                    "
                    [attr.data-text]="
                        'dynamische_frontend_texte.datum_auswahl.html_content.zeit_auswahl_eintrag'
                            | translate
                            | replaceDateTime: singleTime.time
                            | async
                    "
                ></a>
                <a
                    *ngIf="!first"
                    tabindex="0"
                    [attr.title]="
                        'dynamische_frontend_texte.datum_auswahl.attribute_text.zeit_auswahl_eintrag_title'
                            | translate
                            | replaceDateTime: singleTime.time
                            | async
                    "
                    (click)="timeClick(singleTime, $event)"
                    href="#"
                    [innerHTML]="
                        'dynamische_frontend_texte.datum_auswahl.html_content.zeit_auswahl_eintrag'
                            | translate
                            | replaceDateTime: singleTime.time
                            | async
                    "
                    [attr.data-text]="
                        'dynamische_frontend_texte.datum_auswahl.html_content.zeit_auswahl_eintrag'
                            | translate
                            | replaceDateTime: singleTime.time
                            | async
                    "
                ></a>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="timePickSecondLoading">
    <div class="col-6">
        <otb-loading-animation-dots
            [text]="'dynamische_frontend_texte.lade_animation.zeiten'"
        ></otb-loading-animation-dots>
    </div>
</div>
