import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, tap } from 'rxjs';
import { AvailableDatesResponse } from '../../../api/models/available-dates-response';
import { AvailableDate } from '../../../api/models/available-date';
import { CalendarService } from '../calendar.service';
import { AvailableTimesResponse } from '../../../api/models/available-times-response';
import { BookingService } from '../../booking/booking.service';
import { TranslateService } from '@ngx-translate/core';
import { DynamicLocaleId } from '../../language/DynamicLocaleId';
import { CalendarLoadTranslationService } from '../calendar-load-translation.service';
import { TranslationData } from '../../../interfaces/translation-data';
import { I18NTranslationResponse } from '../../../api/models/i-18-n-translation-response';
import { ApplicationSettingsService } from '../../application-settings/application-settings.service';
import { CalendarFactoryService } from '../calendar-factory.service';
import { PikadayOptionsService } from '../pikaday/pikaday-options.service';

@Injectable({
    providedIn: 'root'
})
export class CalendarDateFirstService {
    activeCalendar: any;
    subs: Subscription = new Subscription();
    timesLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    timePickSecondLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private dynamicLocaleId: DynamicLocaleId;

    constructor(
        private calendarService: CalendarService,
        private bookingService: BookingService,
        private translateService: TranslateService,
        private calendarLoadTranslationService: CalendarLoadTranslationService,
        private applicationSettingsService: ApplicationSettingsService,
        private calendarFactoryService: CalendarFactoryService,
        private pikaOptionsService: PikadayOptionsService
    ) {
        this.dynamicLocaleId = new DynamicLocaleId(this.translateService);
    }

    initializeCalendar(container: HTMLDivElement, inputElement: HTMLInputElement): void {
        let availDates: AvailableDate[];
        this.getAvailableDates().subscribe({
            next: (dates: AvailableDatesResponse) => {
                availDates = dates.data;
                this.loadAndInitializeCalendar(container, inputElement, availDates);
            },
            error: (e) => {
                console.error(e);
                throw new Error('Error getting calendar');
            },
            complete: () => {}
        });
    }

    private getAvailableDates(): Observable<AvailableDatesResponse> {
        return this.calendarService.getAvailableDates().pipe(
            tap((dates: AvailableDatesResponse) => {
                this.calendarService.availableDates.next(dates.data);
            })
        );
    }

    private loadAndInitializeCalendar(
        container: HTMLDivElement,
        inputElement: HTMLInputElement,
        availDates: AvailableDate[]
    ): void {
        const locale = this.dynamicLocaleId.toString();
        this.calendarLoadTranslationService
            .getI18NTranslationData(locale)
            .subscribe((i18nResponse: I18NTranslationResponse) => {
                const translationData = <TranslationData>JSON.parse(<string>i18nResponse.data);
                const options = this.pikaOptionsService.buildCalendarOptions(
                    container,
                    inputElement,
                    this.onDateSelect,
                    availDates,
                    translationData
                );
                this.initializePikadayComponent(options);
            });
    }

    private initializePikadayComponent(options: any): void {
        if (this.activeCalendar) {
            this.activeCalendar.destroy();
        }
        const pikadayComponent = this.calendarFactoryService.buildCalendar();

        this.activeCalendar = pikadayComponent.init(options);
        // TODO is this really needed?
        if (!isNaN(<number>this.bookingService.booking.date?.getTime())) {
            this.onDateSelect(this.bookingService.booking.date);
        }
    }

    onDateSelect = (selectedDate: Date = new Date()): void => {
        this.timesLoading.next(true);
        selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
        this.calendarService.selectedDate.next(selectedDate);
        this.bookingService.booking.date = selectedDate;
        this.timePickSecondLoading.next(true);

        const calender$ = this.calendarService
            .getAvailableTimesWithDate(selectedDate, this.applicationSettingsService.serviceOnly)
            .subscribe({
                next: (result: AvailableTimesResponse) => {
                    this.calendarService.availableTimes.next(result.data);
                    this.timePickSecondLoading.next(false);
                },
                error: (e) => {
                    console.error(e);
                    throw new Error('Error getting available times');
                },
                complete: () => {}
            });
        this.subs.add(calender$);
    };
}
