<span class="breadcrumb-item d-flex mb-2" [class.is--last]="isLast">
    <a routerLink="{{ breadcrumb.url }}" (click)="onBreadcrumbClick()">
        <span [innerHTML]="breadcrumb.name | translate | safeStyle"></span>
        <ng-template
            [otbShowInEnv]="['default', 'sh', 'education-check', 'finance', 'justice', 'hmdj', 'hzd-internal']"
        >
            <fa-icon class="fa" [icon]="faEdit" *ngIf="!isLast"></fa-icon>
        </ng-template>
    </a>
</span>
