import { Injectable } from '@angular/core';
import { AvailableDate } from '../../../api/models/available-date';
import { TranslationData } from '../../../interfaces/translation-data';
import { BookingService } from '../../booking/booking.service';
import { StateService } from '../../state/state.service';

@Injectable({
    providedIn: 'root'
})
export class PikadayOptionsService {
    constructor(
        private bookingService: BookingService,
        private stateService: StateService
    ) {}

    buildCalendarOptions(
        container: HTMLDivElement,
        inputElement: HTMLInputElement,
        onDateSelect: Function,
        dates: AvailableDate[],
        translationData: TranslationData
    ): any {
        const monthFutureOffset = this.stateService.getCurrentState().context.bookingTimeframe;
        const enabledDates = dates.map((dateString: AvailableDate) => {
            return dateString.day;
        });
        const today = new Date();
        const startDate = new Date(today.getFullYear(), today.getMonth());
        const endDate = new Date(today.getFullYear(), today.getMonth() + monthFutureOffset, 0);

        this.bookingService.booking.date = this.bookingService.booking.date
            ? this.bookingService.booking.date
            : new Date(enabledDates[0]);

        return {
            field: inputElement,
            firstDay: 1,
            yearRange: 1,
            bound: false,
            container: container,
            disableWeekends: true,
            setDefaultDate: true,
            enableSelectionDaysInNextAndPreviousMonths: true,
            showLegend: true,
            i18n: translationData.pikaday,
            minDate: startDate,
            maxDate: endDate,
            defaultDate: this.bookingService.booking.date,
            disabledDates: enabledDates,
            disableDayFn: (theDate: Date): boolean => {
                theDate.setMinutes(theDate.getMinutes() - theDate.getTimezoneOffset());
                return !enabledDates.includes(theDate.toISOString().split('T')[0]);
            },
            onSelect: onDateSelect
        };
    }
}
