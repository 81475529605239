<div class="main-wrapper">
    <otb-top-breadcrumb #breadcrumbs *otbShowInEnv="['mags-sop']"></otb-top-breadcrumb>
    <otb-header *ngIf="!headlessMode"></otb-header>
    <!-- true -> workaround for mags -->
    <div class="container" style="{{ breadcrumbsHeightMargin }}">
        <main>
            <otb-warning></otb-warning>
            <router-outlet
                *ngIf="(applicationSettingsLoaded | async) && !errorLoadingApplicationSettings()"
            ></router-outlet>
            <otb-skeleton-loader-main
                *ngIf="(applicationSettingsLoaded | async) === false && !errorLoadingApplicationSettings()"
            ></otb-skeleton-loader-main>
        </main>
    </div>

    <div class="container" *ngIf="errorLoadingApplicationSettings()">
        <div class="col-12">
            <h3
                [innerHTML]="'dynamische_frontend_texte.error_message.application_settings' | translate | safeStyle"
            ></h3>
        </div>
    </div>
</div>

<!-- Footer -->
<ng-container [ngSwitch]="env">
    <ng-container *ngSwitchCase="'sh'">
        <otb-footer-sh></otb-footer-sh>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <otb-footer *ngIf="!headlessMode"></otb-footer>
    </ng-container>
</ng-container>

<div class="d-none">{{ theme }}</div>
