<div class="row">
    <div class="col-12">
        <p class="addition">
            <span
                [innerHTML]="'dynamische_frontend_texte.landing.html_content.ueberschrift' | translate | safeStyle"
            ></span>
        </p>
    </div>
    <otb-progress-bar></otb-progress-bar>
</div>
<form id="agency-service-selection" [formGroup]="serviceFormGroup" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-12 mb-4">
            <h2 [innerHTML]="'dynamische_frontend_texte.landing.html_content.einfuehrung' | translate | safeStyle"></h2>
        </div>
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <label
                    for="citizen-service-only"
                    [innerHTML]="
                        'dynamische_frontend_texte.landing.html_content.label_dropdown_leistung'
                            | translate
                            | star
                            | safeStyle
                    "
                ></label>
                <ng-select
                    id="citizen-service-only"
                    [items]="servicesSelect"
                    bindLabel="name"
                    bindValue="id"
                    (change)="onServiceSelect($event)"
                    formControlName="service"
                    [clearable]="false"
                    placeholder="Dienstleistung auswählen"
                ></ng-select>
                <otb-form-error-message [show]="formService.invalid && formService.dirty"></otb-form-error-message>
            </div>
        </div>
        <div class="col-0 col-sm-6"></div>
        <div class="col-12 col-sm-6">
            <div class="service-notice mt-3" *ngIf="selectedService">
                <span
                    class="description-title"
                    [innerHTML]="
                        'dynamische_frontend_texte.landing.html_content.zusaetzliche_informationen'
                            | translate
                            | safeStyle
                    "
                ></span>
                <p class="mt-2" [innerHTML]="selectedService.notice | safeStyle"></p>
            </div>

            <button
                type="submit"
                class="btn-success btn rounded-0 float-end"
                accesskey="w"
                [title]="'dynamische_frontend_texte.landing.attribute_text.button_text' | translate"
                [value]="'dynamische_frontend_texte.landing.attribute_text.button_text' | translate"
                [innerHTML]="'dynamische_frontend_texte.landing.html_content.button_text' | translate | safeStyle"
            ></button>
        </div>
    </div>
</form>
