<label
    for="citizen-agency"
    id="citizen-agency-label"
    [innerHTML]="
        'dynamische_frontend_texte.landing.html_content.label_dropdown_dienststelle' | translate | star | safeStyle
    "
></label>
<ng-select
    id="citizen-agency"
    [items]="agenciesSelect"
    bindLabel="name"
    bindValue="id"
    (change)="onAgencySelect($event)"
    formControlName="agency"
    [clearable]="false"
    placeholder="Dienststelle auswählen"
    [loading]="agenciesRequestLoading"
    loadingText="Lade Dienststellen..."
    aria-labelledby="citizen-agency-label"
></ng-select>
<otb-form-error-message [show]="formAgency.invalid && formAgency.dirty"></otb-form-error-message>
