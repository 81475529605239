import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { Agency } from '../../../api/models/agency';
import { WarningService } from '../../../services/warning/warning.service';
import { BookingService } from '../../../services/booking/booking.service';
import { AgencyService } from '../../../services/agency/agency.service';
import { Subscription } from 'rxjs';
import { WebcomponentInterfaceService } from '../../../services/webcomponent-interface/webcomponent-interface.service';

@Component({
    selector: 'otb-agency-selection',
    templateUrl: './agency-selection.component.html',
    styleUrls: ['./agency-selection.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective
        }
    ]
})
export class AgencySelectionComponent implements OnInit, OnDestroy {
    @Input({ required: true }) agencyFormGroup!: FormGroup;
    @Output() agencyChange = new EventEmitter<Agency>();
    agenciesSelect: Array<Agency> = [
        {
            id: 0,
            name: 'Dienststelle auswählen',
            external_reference: '',
            address: '',
            phone: '',
            fax: '',
            website: ''
        }
    ];
    agenciesRequestLoading: boolean = true;
    private subs: Subscription = new Subscription();

    constructor(
        private warningService: WarningService,
        private bookingService: BookingService,
        private agencyService: AgencyService,
        private webcompInterfaceService: WebcomponentInterfaceService
    ) {}

    ngOnInit(): void {
        this.agenciesRequestLoading = true;
        this.retrieveAgenciesAjax();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onAgencySelect(agency: Agency): void {
        this.agencyChange.emit(agency);
    }

    private retrieveAgenciesAjax(): void {
        const placeholderAgency = {
            id: 0,
            name: 'Dienststelle auswählen',
            external_reference: '',
            address: '',
            phone: '',
            fax: '',
            website: ''
        };
        const getAgencies$ = this.agencyService.allAgencies$.subscribe((agencies: Array<Agency>) => {
            const hasAgencies = agencies.length > 0;
            if (!hasAgencies) {
                return;
            }
            this.agenciesSelect = hasAgencies ? [placeholderAgency, ...agencies] : [placeholderAgency];
            this.prefillAgency(this.agenciesSelect);
            this.agenciesRequestLoading = false;
        });

        this.subs.add(getAgencies$);

        this.agencyService.retrieveAgencies();
    }

    private prefillAgency(agencies: Array<Agency>): void {
        this.warningService.hideAgencyPreselectionWarning();

        if (this.webcompInterfaceService.agency === '') {
            return;
        }

        const preSelectedAgency = agencies.find(
            (agency) => agency.external_reference === this.webcompInterfaceService.agency
        );
        const blockedAgency: string = this.webcompInterfaceService.blockAgency;

        if (typeof preSelectedAgency === 'object') {
            this.agencyFormGroup.controls['agency'].patchValue(preSelectedAgency.id);
            this.bookingService.booking.agency = preSelectedAgency;
            if (blockedAgency === 'true') {
                this.agencyFormGroup.controls['agency'].disable();
            }
            this.onAgencySelect(preSelectedAgency);
        } else {
            this.warningService.showAgencyPreselectionWarning();
        }
    }

    get formAgency(): AbstractControl {
        return this.agencyFormGroup.get('agency') as AbstractControl;
    }
}
