<div class="row" *ngIf="appointmentCancellation !== null && !success && !error">
    <h2
        class="col-12 conf"
        [innerHTML]="'dynamische_frontend_texte.stornierung.html_content.ueberschrift' | translate | safeStyle"
    ></h2>
    <div class="col-12">
        <p>
            <b
                [innerHTML]="
                    'dynamische_frontend_texte.stornierung.html_content.text_einleitung' | translate | safeStyle
                "
            ></b>
        </p>
        <p
            [innerHTML]="
                'dynamische_frontend_texte.stornierung.html_content.dienststelle_uebersicht'
                    | translate
                    | replace: { dienststelle: appointmentCancellation.court }
            "
        ></p>
        <p
            [innerHTML]="
                'dynamische_frontend_texte.stornierung.html_content.dienstleistung_uebersicht'
                    | translate
                    | replace: { dienstleistung: appointmentCancellation.service }
            "
        ></p>
        <p
            [innerHTML]="
                'dynamische_frontend_texte.stornierung.html_content.datum_uebersicht'
                    | translate
                    | replace
                        : {
                              datum: appointmentCancellation.date,
                              uhrzeit: appointmentCancellation.start_time
                          }
            "
        ></p>

        <p
            *ngIf="appointmentCancellation.last_name"
            [innerHTML]="
                'dynamische_frontend_texte.stornierung.html_content.termin_detail'
                    | translate
                    | replace
                        : {
                              eintrag_schluessel: 'Nachname',
                              eintrag_wert: appointmentCancellation.last_name
                          }
            "
        ></p>
        <p
            *ngIf="appointmentCancellation.first_name"
            [innerHTML]="
                'dynamische_frontend_texte.stornierung.html_content.termin_detail'
                    | translate
                    | replace
                        : {
                              eintrag_schluessel: 'Vorname',
                              eintrag_wert: appointmentCancellation.first_name
                          }
            "
        ></p>
        <p
            *ngIf="appointmentCancellation.email_address"
            [innerHTML]="
                'dynamische_frontend_texte.stornierung.html_content.termin_detail'
                    | translate
                    | replace
                        : {
                              eintrag_schluessel: 'E-Mail-Adresse',
                              eintrag_wert: appointmentCancellation.email_address
                          }
            "
        ></p>
    </div>
    <div class="col-12">
        <button
            (click)="confirmCancel()"
            [innerHTML]="'dynamische_frontend_texte.stornierung.html_content.button_text' | translate | safeStyle"
            id="appointment_cancellation_submit"
            class="btn-success btn"
        ></button>
    </div>
</div>

<div class="row" *ngIf="appointmentCancellation === null">
    <h2
        class="col-12 conf"
        [innerHTML]="'dynamische_frontend_texte.stornierung.html_content.ueberschrift' | translate | safeStyle"
    ></h2>
    <p
        class="col-12 conf"
        [innerHTML]="
            'dynamische_frontend_texte.stornierung.html_content.text_nicht_erfolgreich' | translate | safeStyle
        "
    ></p>
</div>

<div class="row" *ngIf="success === true">
    <h2
        class="col-12 conf"
        [innerHTML]="'dynamische_frontend_texte.stornierung.html_content.ueberschrift' | translate | safeStyle"
    ></h2>
    <p
        class="col-12 conf"
        [innerHTML]="'dynamische_frontend_texte.stornierung.html_content.text_erfolgreich' | translate | safeStyle"
    ></p>
</div>

<div class="row" *ngIf="error === true">
    <h2
        class="col-12 conf"
        [innerHTML]="'dynamische_frontend_texte.stornierung.html_content.ueberschrift' | translate | safeStyle"
    ></h2>
    <p class="col-12 conf">
        Leider konnte ihr Termin nicht storniert werden. Bitte wenden Sie sich an ihr
        <span [innerHTML]="'general.service_provider_singular' | translate | safeStyle"></span>.
    </p>
</div>
