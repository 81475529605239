import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApplicationSettingsService {
    serviceOnly: boolean = false;
    onlineService: boolean = false;
    showEasyLanguagePage: boolean = false;
    applicationSettingsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    errorLoadingApplicationSettings: WritableSignal<boolean> = signal(false);
    constructor() {}

    toJson(): object {
        return {
            serviceOnly: this.serviceOnly,
            onlineService: this.onlineService,
            showEasyLanguagePage: this.showEasyLanguagePage
        };
    }
    parseFromJson(json: { serviceOnly?: boolean; onlineService?: boolean; showEasyLanguagePage?: boolean }): any {
        const { serviceOnly, onlineService, showEasyLanguagePage } = json;

        if (serviceOnly) {
            this.serviceOnly = serviceOnly;
        }

        if (onlineService) {
            this.onlineService = onlineService;
        }
        if (showEasyLanguagePage) {
            this.showEasyLanguagePage = showEasyLanguagePage;
        }

        return json;
    }
}
