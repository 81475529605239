<div class="row">
    @if (!done) {
        <h2
            class="col-12"
            [innerHTML]="
                'dynamische_frontend_texte.bestaetigung.html_content.ueberschrift_erfolgreich' | translate | safeStyle
            "
        ></h2>
    } @else if (!error && done) {
        <h2
            class="col-12"
            [innerHTML]="
                'dynamische_frontend_texte.bestaetigung.html_content.ueberschrift_erfolgreich' | translate | safeStyle
            "
        ></h2>
        <p
            class="col-12"
            [innerHTML]="'dynamische_frontend_texte.bestaetigung.html_content.text_erfolgreich' | translate | safeStyle"
        ></p>
    } @else if (error && done && !isAppointmentAlreadyConfirmedError) {
        <h2
            class="col-12"
            [innerHTML]="
                'dynamische_frontend_texte.bestaetigung.html_content.ueberschrift_nicht_erfolgreich'
                    | translate
                    | safeStyle
            "
        ></h2>
        <p
            class="col-12"
            [innerHTML]="
                'dynamische_frontend_texte.bestaetigung.html_content.text_nicht_erfolgreich' | translate | safeStyle
            "
        ></p>
    } @else if (error && done && isAppointmentAlreadyConfirmedError) {
        <h2
            class="col-12"
            [innerHTML]="
                'dynamische_frontend_texte.bestaetigung.html_content.ueberschrift_nicht_erfolgreich'
                    | translate
                    | safeStyle
            "
        ></h2>
        <p
            class="col-12"
            [innerHTML]="
                'dynamische_frontend_texte.bestaetigung.html_content.body_bereits_bestaetigt' | translate | safeStyle
            "
        ></p>
    }
</div>
