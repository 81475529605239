import { Injectable } from '@angular/core';
import { AvailableTime } from '../../../api/models/available-time';
import { AvailableDatesResponse } from '../../../api/models/available-dates-response';
import { CalendarService } from '../calendar.service';
import { AvailableDate } from '../../../api/models/available-date';
import { BookingService } from '../../booking/booking.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { DynamicLocaleId } from '../../language/DynamicLocaleId';
import { TranslateService } from '@ngx-translate/core';
import { CalendarLoadTranslationService } from '../calendar-load-translation.service';
import { TranslationData } from '../../../interfaces/translation-data';
import { I18NTranslationResponse } from '../../../api/models/i-18-n-translation-response';
import { CalendarFactoryService } from '../calendar-factory.service';
import { PikadayComponent } from '../../../components/pikaday/pikaday';
import { StateService } from '../../state/state.service';

@Injectable({
    providedIn: 'root'
})
export class CalendarTimeFirstService {
    activeCalendar: any;
    defaultDate: Date;
    dateChosen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private dynamicLocaleId: DynamicLocaleId;

    constructor(
        private calendarService: CalendarService,
        private bookingService: BookingService,
        private translateService: TranslateService,
        private calendarLoadTranslationService: CalendarLoadTranslationService,
        private calendarFactoryService: CalendarFactoryService,
        private stateService: StateService
    ) {
        this.dynamicLocaleId = new DynamicLocaleId(this.translateService);
    }

    initializeCalendar(container: HTMLDivElement, inputElement: HTMLInputElement, time: AvailableTime): void {
        this.calendarService
            .getAvailableDates(time)
            .pipe(
                tap((dates: AvailableDatesResponse) => {
                    this.calendarService.availableDates.next(dates.data);
                }),
                switchMap((dates: AvailableDatesResponse) => this.getTranslationFileWithDates(dates.data)),
                map((data: { dates: AvailableDate[]; translationData: TranslationData }) =>
                    this.buildCalendarTimeFirstOptions(container, inputElement, data.dates, data.translationData)
                )
            )
            .subscribe({
                next: (options) => {
                    this.initializeCalendarComponent(options);
                },
                error: (e) => {
                    console.error(e);
                    throw new Error('Error getting calendar');
                },
                complete: () => {}
            });
    }

    private initializeCalendarComponent(options: any): void {
        if (this.activeCalendar) {
            this.activeCalendar.destroy();
        }
        const pikadayComponent: PikadayComponent = this.calendarFactoryService.buildCalendar();
        this.activeCalendar = pikadayComponent.init(options);
    }

    private getTranslationFileWithDates(dates: AvailableDate[]): Observable<{
        dates: AvailableDate[];
        translationData: TranslationData;
    }> {
        const locale = this.dynamicLocaleId.toString();
        return this.calendarLoadTranslationService.getI18NTranslationData(locale).pipe(
            map((i18nResponse: I18NTranslationResponse) => {
                const translationData = <TranslationData>JSON.parse(<string>i18nResponse.data);
                return { dates: dates, translationData: translationData };
            })
        );
    }

    private buildCalendarTimeFirstOptions(
        container: HTMLDivElement,
        inputElement: HTMLInputElement,
        dates: AvailableDate[],
        translationData: TranslationData
    ): any {
        const monthFutureOffset = this.stateService.getCurrentState().context.bookingTimeframe;
        const enabledDates = dates.map((dateString: AvailableDate) => {
            return dateString.day;
        });

        const today = new Date();
        const startDate = new Date(today.getFullYear(), today.getMonth());
        const endDate = new Date(today.getFullYear(), today.getMonth() + monthFutureOffset, 0);
        this.calendarService.defaultDate = this.bookingService.booking.date
            ? this.bookingService.booking.date
            : new Date(enabledDates[0]);

        return this.getCalendarOptions(container, inputElement, enabledDates, translationData, startDate, endDate);
    }

    private getCalendarOptions(
        container: HTMLDivElement,
        inputElement: HTMLInputElement,
        enabledDates: string[],
        translationData: any,
        startDate: Date,
        endDate: Date
    ): any {
        return {
            field: inputElement,
            firstDay: 1,
            yearRange: 1,
            bound: false,
            container: container,
            disableWeekends: true,
            setDefaultDate: true,
            enableSelectionDaysInNextAndPreviousMonths: true,
            showLegend: true,
            i18n: translationData.pikaday,
            minDate: startDate,
            maxDate: endDate,
            defaultDate: this.calendarService.defaultDate,
            disabledDates: enabledDates,
            disableDayFn: (theDate: Date): boolean => {
                theDate.setMinutes(theDate.getMinutes() - theDate.getTimezoneOffset());
                return !enabledDates.includes(theDate.toISOString().split('T')[0]);
            },
            onSelect: (selectedDate: Date = new Date()): void => {
                this.bookingService.booking.date = selectedDate;
                this.dateChosen.next(true);
            }
        };
    }
}
