<footer class="mt-5 mt-lg-0">
    <div class="container p-0">
        <div class="footerRotate row d-block">
            <div class="footerRotateBG">
                <div class="slot">
                    <div class="row">
                        <nav class="col-12 columns bgFooter inner-container">
                            <div class="navServiceMeta">
                                <div class="footerLine">
                                    <ul class="rightFooterNavi">
                                        <li>
                                            <a
                                                [attr.href]="
                                                    'dynamische_frontend_texte.footer.attribute_text.impressum_link'
                                                        | translate
                                                "
                                                [innerHTML]="
                                                    'dynamische_frontend_texte.footer.html_content.impressum'
                                                        | translate
                                                        | safeStyle
                                                "
                                                title="Impressum"
                                                class=""
                                            >
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                [attr.href]="
                                                    'dynamische_frontend_texte.footer.attribute_text.datenschutz_link'
                                                        | translate
                                                "
                                                [innerHTML]="
                                                    'dynamische_frontend_texte.footer.html_content.datenschutz'
                                                        | translate
                                                        | safeStyle
                                                "
                                                title="Datenschutzerklärung"
                                                class=""
                                            >
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                [attr.href]="
                                                    'dynamische_frontend_texte.footer.attribute_text.barrierefreiheit_link'
                                                        | translate
                                                "
                                                [innerHTML]="
                                                    'dynamische_frontend_texte.footer.html_content.barrierefreiheit'
                                                        | translate
                                                "
                                                class=""
                                            ></a>
                                        </li>
                                        <li>
                                            <a
                                                [attr.href]="
                                                    'dynamische_frontend_texte.footer.attribute_text.sitemap_link'
                                                        | translate
                                                "
                                                [innerHTML]="
                                                    'dynamische_frontend_texte.footer.html_content.sitemap'
                                                        | translate
                                                        | safeStyle
                                                "
                                                title="Sitemap"
                                                class=""
                                            >
                                            </a>
                                        </li>
                                    </ul>
                                    <div
                                        class="copyright medium-6 columns"
                                        [innerHTML]="
                                            'dynamische_frontend_texte.footer.html_content.copyright'
                                                | translate
                                                | safeStyle
                                        "
                                    ></div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
