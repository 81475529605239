<div class="row">
    <otb-inner-breadcrumb
        *otbShowInEnv="['default', 'sh', 'education-check', 'finance', 'justice', 'hmdj', 'hzd-internal']"
    ></otb-inner-breadcrumb>
    <otb-progress-bar></otb-progress-bar>
</div>

<div class="row" [class.d-none]="calendarLoading" *ngIf="calendarShow; else noTimeSlots">
    <div class="col-12">
        <h2
            *otbHideInEnv="['hzd-internal']"
            [innerHTML]="'dynamische_frontend_texte.datum_auswahl.html_content.ueberschrift' | translate | safeStyle"
        ></h2>
        <h1
            *otbShowInEnv="['hzd-internal']"
            [innerHTML]="'dynamische_frontend_texte.datum_auswahl.html_content.ueberschrift' | translate | safeStyle"
        ></h1>
    </div>
    <div class="col-12 mb-5" *ngIf="showFormSwitcherTop && !isServiceOnly">
        <div class="changeform__container">
            <a
                (click)="switchToTimeFirst($event)"
                href="#"
                class="changeform has--before-arrow"
                title="{{
                    'dynamische_frontend_texte.datum_auswahl.attribute_text.suche_fuer_bestimmte_zeit' | translate
                }}"
                aria-label="Wechsel zur Uhrzeit-Ansicht"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.html_content.suche_fuer_bestimmte_zeit'
                        | translate
                        | safeStyle
                "
            >
            </a>
        </div>
    </div>
    <div class="col-12 col-md-6 mb-5">
        <div class="upper__container mb-5 mb-sm-2">
            <p
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.html_content.label_kalendar' | translate | safeStyle
                "
            ></p>
        </div>
        <div #calendarContainer class="mb-3" [class.colorblind-active]="colorLegendShow | async">
            <input type="hidden" #datepicker />
        </div>

        <div class="col-12 mb-5" *ngIf="!showFormSwitcherTop && !isServiceOnly">
            <div class="changeform__container">
                <a
                    (click)="switchToTimeFirst($event)"
                    href="#"
                    class="changeform has--before-arrow"
                    title="{{
                        'dynamische_frontend_texte.datum_auswahl.attribute_text.suche_fuer_bestimmte_zeit' | translate
                    }}"
                    aria-label="Wechsel zur Uhrzeit-Ansicht"
                    [innerHTML]="
                        'dynamische_frontend_texte.datum_auswahl.html_content.suche_fuer_bestimmte_zeit'
                            | translate
                            | safeStyle
                    "
                >
                </a>
                <fa-icon class="fa" [icon]="faArrowCircleRight"></fa-icon>
            </div>
        </div>
        <otb-color-legend></otb-color-legend>
    </div>
    <div class="col-12 col-md-6">
        <!-- if application_settings service_only is false, show only regular process -->
        <ng-container *ngIf="!timesLoading; else timesLoadingTemplate">
            <ng-container *ngIf="!isServiceOnly; then courtAndService; else serviceOnly"></ng-container>
        </ng-container>
        <ng-template #courtAndService>
            <otb-time-pick-selection
                [availableAgencies]="availableAgencyTimes"
                (timeSelect)="onTimeSelect($event)"
            ></otb-time-pick-selection>
            <ng-template *ngIf="appointmentReservationLoading | async; then reservationLoading"></ng-template>
        </ng-template>
        <!-- else show service only -->
        <ng-template #serviceOnly>
            <otb-service-only-time-pick
                [availableDetailedAgencies]="availableDetailedAgencyTimes"
                (timeSelect)="onTimeSelectServiceOnly($event)"
            ></otb-service-only-time-pick>
            <ng-template *ngIf="appointmentReservationLoading | async; then reservationLoading"></ng-template>
        </ng-template>

        <ng-template #timesLoadingTemplate>
            <otb-loading-animation-dots
                [text]="'dynamische_frontend_texte.lade_animation.zeiten'"
            ></otb-loading-animation-dots>
        </ng-template>

        <ng-template #reservationLoading>
            <div class="d-flex mt-3 align-items-center justify-content-center">
                <div class="mx-3 d-inline">
                    <span>Ihr Termin wird reserviert...</span>
                </div>

                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<div class="row" *ngIf="calendarLoading">
    <div class="col-12">
        <otb-loading-animation-dots
            [text]="'dynamische_frontend_texte.lade_animation.kalender'"
        ></otb-loading-animation-dots>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <a
            (click)="back()"
            (keydown.enter)="back()"
            class="btn-secondary btn pull-left order-md-1 order-2"
            [title]="'dynamische_frontend_texte.datum_auswahl.attribute_text.zurueck' | translate"
            [innerHTML]="'dynamische_frontend_texte.datum_auswahl.html_content.zurueck' | translate | safeStyle"
            accesskey="z"
            tabindex="0"
        >
        </a>
    </div>
</div>

<ng-template #noTimeSlots>
    <div class="row">
        <div class="col-12">
            <p
                class="info__header"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.html_content.ueberschrift_keine_termine'
                        | translate
                        | safeStyle
                "
            ></p>
            <p
                class="info__content"
                [innerHTML]="
                    'dynamische_frontend_texte.datum_auswahl.html_content.naechste_freie_termine'
                        | translate
                        | replaceDateTime: nextAppointmentAt
                        | async
                "
            ></p>
        </div>
    </div>
</ng-template>
