import { TranslateService } from '@ngx-translate/core';
import { TextsService } from '../../api/services/texts.service';
import { Injectable } from '@angular/core';
import messages from 'src/app/services/translate-api/messages.json';
import { ApplicationLoadedService } from '../application-loaded/application-loaded.service';

@Injectable({ providedIn: 'root' })
export class TranslationState {
    constructor(
        private translateService: TranslateService,
        private api: TextsService,
        private appLoadedService: ApplicationLoadedService
    ) {}

    /**
     * Loads the translation for the given language.
     * Normally, this is done by implementing a TranslateLoader. In our case, it is not possible
     * to do so, because the Loader is injected before the endpoint url is known.
     *
     * @param language
     */
    prepareState(language: string): void {
        this.preloadDefaultLanguage(language);
        const translations: any = {};
        const apiRootUrlTemp = this.api.rootUrl;

        this.api
            .getApiPublicText({
                _locale: <any>language?.toLowerCase()
            })
            .subscribe((response: any) => {
                response.data.forEach((textEntity: any) => {
                    translations[textEntity.text_id as keyof unknown] = textEntity.text_value;
                    this.translateService.setTranslation(language, translations);
                    this.translateService.setDefaultLang(language);
                    this.translateService.use(language);
                    this.appLoadedService.languageLoaded.set(true);
                });
            });

        this.api.rootUrl = apiRootUrlTemp;
    }

    private preloadDefaultLanguage(language: string): void {
        const translations: any = {};
        messages.data.forEach((textEntity: any) => {
            translations[textEntity.text_id as keyof unknown] = textEntity.text_value;
            this.translateService.setTranslation(language, translations);
            this.translateService.setDefaultLang(language);
            this.translateService.use(language);
        });
    }
}
