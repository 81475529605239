<header [ngClass]="headerClasses" #header>
    <div class="header-top" #headerTop>
        <otb-error-flash-message></otb-error-flash-message>
        <div class="container">
            <div class="row">
                <div class="col-2 logo-container" *otbShowInEnv="['default']">
                    <ng-container *ngIf="appLoaded(); else logoSkeleton">
                        <img *ngIf="showUploadedLogo; else defaultLogo" [src]="rootUrl + '/logo/frontend'" alt="logo" />
                        <ng-template #defaultLogo>
                            <img [src]="rootUrl + '/assets/img/default/logo.png'" alt="logo" />
                        </ng-template>
                    </ng-container>
                </div>

                <div class="col-4 logo-container d-none d-lg-block" *otbShowInEnv="['sh']">
                    <ng-container *ngIf="appLoaded(); else logoSkeleton">
                        <img
                            *ngIf="showUploadedLogo; else shDesktopLogo"
                            [src]="rootUrl + '/logo/frontend'"
                            alt="logo"
                        />
                        <ng-template #shDesktopLogo>
                            <img [src]="rootUrl + '/assets/img/sh/logo.png'" alt="logo" />
                        </ng-template>
                    </ng-container>
                </div>

                <div class="col-4 logo-container d-block d-lg-none" *otbShowInEnv="['sh']">
                    <ng-container *ngIf="appLoaded(); else logoSkeleton">
                        <img
                            *ngIf="showUploadedLogo; else shMobileLogo"
                            [src]="rootUrl + '/logo/frontend'"
                            alt="logo"
                        />
                        <ng-template #shMobileLogo>
                            <img [src]="rootUrl + '/assets/img/sh/logo.png'" alt="logo" />
                        </ng-template>
                    </ng-container>
                </div>
                <ng-template #logoSkeleton>
                    <otb-skeleton-loader-logo></otb-skeleton-loader-logo>
                </ng-template>

                <div class="col-2 offset-10" *ngIf="hasMultipleLanguages && !headless">
                    <div class="p-2 position-absolute lang-pick-container" #langPickContainer>
                        <otb-language-picker *otbShowInEnv="['mags-sop', 'justice']"></otb-language-picker>
                    </div>
                </div>
                <div class="col 12 d-flex justify-content-between align-items-center" *otbShowInEnv="['finance']">
                    <div>
                        <a
                            *ngIf="showEasyLanguagePage"
                            class="top-header-item"
                            [routerLink]="'/leichte_sprache'"
                            aria-label="Link zur Leichte Sprache"
                        >
                            <span class="contact">
                                <fa-icon class="fa" [icon]="faBookReader"></fa-icon>Leichte Sprache
                            </span>
                        </a>
                    </div>
                    <div>
                        <a
                            class="top-header-item"
                            href="https://www.finanzverwaltung.nrw.de/de/kontakt"
                            target="_blank"
                            rel="noopener"
                            aria-label="KONTAKT - Dieser Link wird in einer neuen Tab geöffnet"
                        >
                            <span class="contact"> <fa-icon class="fa" [icon]="faPaperPlane"></fa-icon>Kontakt </span>
                        </a>
                        <a
                            class="top-header-item"
                            [routerLink]="'/barrierefreiheit'"
                            aria-label="Link zur Erklärung der Barrierefreiheit"
                            >Barrierefreiheit</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="head" *otbShowInEnv="['justice', 'default', 'sh', 'education-check', 'finance', 'hmdj']">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-8 order-1">
                    <otb-skeleton-loader-header
                        *ngIf="!appLoaded() && !errorLoadingApplicationSettings(); else mainText"
                    ></otb-skeleton-loader-header>
                    <ng-template #mainText>
                        <a
                            aria-label="'dynamische_frontend_texte.header.attribute_text.page_reload' | translate"
                            (click)="clearStorage()"
                            [routerLink]="getRootUrl()"
                            class="headline-anchor"
                        >
                            <h1
                                class="headline"
                                [innerHTML]="
                                    'dynamische_frontend_texte.header.html_content.ueberschrift' | translate | safeStyle
                                "
                            ></h1>

                            <p
                                class="subheader"
                                [innerHTML]="
                                    'dynamische_frontend_texte.header.html_content.sub_ueberschrift'
                                        | translate
                                        | safeStyle
                                "
                                *otbShowInEnv="['education-check', 'finance', 'justice', 'hmdj']"
                            ></p>
                        </a>
                    </ng-template>
                </div>
                <div
                    class="col-12 col-sm-4 order-0 order-sm-2 logo-container logo-mobile--sm"
                    *otbShowInEnv="['education-check']"
                >
                    <ng-container *ngIf="appLoaded(); else logoSkeleton">
                        <img
                            *ngIf="showUploadedLogo; else educationCheckLogo"
                            class="logo-image"
                            [src]="rootUrl + '/logo/frontend'"
                            alt="Bildungsscheck Logo"
                        />
                        <ng-template #educationCheckLogo>
                            <img
                                class="logo-image"
                                [src]="rootUrl + '/assets/img/education-check/bildungsscheck_logo.jpg'"
                                alt="Bildungsscheck Logo"
                            />
                        </ng-template>
                    </ng-container>
                </div>
                <div
                    class="col-12 col-sm-4 order-0 order-sm-2 logo-container logo-mobile--sm"
                    *otbShowInEnv="['finance']"
                >
                    <ng-container *ngIf="appLoaded(); else logoSkeleton">
                        <img
                            *ngIf="showUploadedLogo; else financeLogo"
                            class="logo-image"
                            [src]="rootUrl + '/logo/frontend'"
                            alt="Finanzministerium Logo"
                        />
                        <ng-template #financeLogo>
                            <img
                                class="logo-image"
                                [src]="rootUrl + '/assets/img/finance/headline_logo.svg'"
                                alt="Finanzministerium Logo"
                            />
                        </ng-template>
                    </ng-container>
                </div>
            </div>
            <div class="row position-relative" *otbShowInEnv="['justice', 'default', 'hmdj']">
                <div class="tel-box" *ngIf="appLoaded() && !errorLoadingApplicationSettings()">
                    <p
                        [innerHTML]="'dynamische_frontend_texte.header.html_content.kontakt' | translate | safeStyle"
                    ></p>
                    <fa-icon
                        [icon]="phoneIcon"
                        [styles]="{
                            stroke: 'white',
                            color: 'white',
                            height: '12px',
                            'margin-right': '12px'
                        }"
                    >
                    </fa-icon>
                    <a
                        [attr.href]="
                            'tel:' + ('dynamische_frontend_texte.header.attribute_text.tel-international' | translate)
                        "
                        [attr.title]="'dynamische_frontend_texte.header.attribute_text.tel-visible' | translate"
                        [innerHTML]="
                            'dynamische_frontend_texte.header.html_content.tel-visible' | translate | safeStyle
                        "
                    >
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="head" *otbShowInEnv="['hzd-internal']">
        <div class="container">
            <div class="row">
                <div class="col-12 order-0 order-sm-0 logo-container logo-mobile--sm" *otbShowInEnv="['hzd-internal']">
                    <div class="d-inline-block">
                        <ng-container *ngIf="appLoaded(); else logoSkeleton">
                            <img
                                *ngIf="showUploadedLogo; else defaultHessenLogo"
                                class="logo-image"
                                [src]="rootUrl + '/logo/frontend'"
                                alt="Hessen Logo"
                            />
                            <ng-template #defaultHessenLogo>
                                <img
                                    class="logo-image"
                                    [src]="rootUrl + '/assets/img/hzd-internal/logo.hessen.png'"
                                    alt="Hessen Logo"
                                />
                            </ng-template>
                        </ng-container>
                    </div>
                    <div class="d-inline-block header-text-container">
                        <a
                            aria-label="'dynamische_frontend_texte.header.attribute_text.page_reload' | translate"
                            (click)="clearStorage()"
                            [routerLink]="getRootUrl()"
                            class="headline-anchor"
                        >
                            <span
                                class="headline"
                                [innerHTML]="'dynamische_frontend_texte.header.html_content.ueberschrift' | translate"
                            ></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
