import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { WebcomponentInterfaceService } from '../../services/webcomponent-interface/webcomponent-interface.service';

@Component({
    selector: 'otb-element',
    templateUrl: './element.component.html',
    styleUrls: ['./element.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ElementComponent implements OnInit {
    @Input() headless: string = 'false';
    @Input() blockAgency: string = 'false';
    @Input() agency: string = '';
    @Input() blockService: string = 'false';
    @Input() service: string = '';
    @Input() endpoint: string = '';
    @Input() language: string = '';
    @Input() cancelBookingEvent: Subject<boolean> = new Subject<boolean>();

    @Input() cancelBooking(): void {
        this.cancelBookingEvent.next(true);
    }

    theme: string = environment.deploy_environment;
    constructor(private webcompInterface: WebcomponentInterfaceService) {}

    ngOnInit(): void {
        this.extraceWebcomponentVariables();
    }

    private extraceWebcomponentVariables(): void {
        if (this.agency) {
            this.webcompInterface.agency = this.agency;
        }

        if (this.service) {
            this.webcompInterface.service = this.service;
        }

        if (this.endpoint) {
            this.webcompInterface.endpoint = this.endpoint;
        }

        this.webcompInterface.language = this.language ?? 'de';
        this.webcompInterface.headless = this.headless;
        this.webcompInterface.blockAgency = this.blockAgency;
        this.webcompInterface.blockService = this.blockService;
        this.webcompInterface.cancelBooking = this.cancelBookingEvent;
    }
}
