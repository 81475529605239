<div class="row final-page">
    <div class="col-12">
        <h1
            *otbShowInEnv="['hzd-internal']"
            [innerHTML]="'dynamische_frontend_texte.danke.html_content.ueberschrift' | translate | safeStyle"
        ></h1>
        <h2
            *otbHideInEnv="['hzd-internal']"
            [innerHTML]="'dynamische_frontend_texte.danke.html_content.ueberschrift' | translate | safeStyle"
        ></h2>
    </div>
    <div class="col-12">
        <p [innerHTML]="'dynamische_frontend_texte.danke.html_content.line1' | translate | safeStyle"></p>
    </div>
    <div class="col-12">
        <p [innerHTML]="'dynamische_frontend_texte.danke.html_content.line2' | translate | safeStyle"></p>
    </div>
</div>
